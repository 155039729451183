<template>
  <div />
</template>

<script>
export default {
  name: 'PCISuccess',
  mounted () {
    window.parent.successCallBack({
      cardToken: this.$route.query.cardToken || '',
      cardType: this.$route.query.cardType || '',
      cardNumber: this.$route.query.cardNumber || '',
      expiration: this.$route.query.expiration || '',
      cardHolderName: this.$route.query.cardHolderName || ''
    })
  }
}
</script>

<style scoped>

</style>
